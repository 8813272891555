import axios from '@axios'
import { subscribeSymbol } from '@/services/binance/spot/binance'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {

    getComprasAbertas(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios.get('ordem-compra', { params: queryParams })
          .then(response => {
            // assinar as moedas presentes na ordem para receber informações
            response.data.content.forEach(currency => {
              subscribeSymbol(currency.moedaParidade)
            })

            resolve(response)
          })
          .catch(error => reject(error))
      })
    },

    cancelarOrdem(ctx, id) {
      return new Promise((resolve, reject) => {
        axios.delete(`ordem-compra/${id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },

  },
}
